<template>
  <div class="container">
    <div
      class="column"
      v-for="n in columnCount"
      :key="n"
      ref="columns"
    >
      <div
        class="item"
        v-for="(item, index) in getItemsForColumn(n)"
        :key="index"
      >
        <!-- 瀑布流项内容 -->
        <slot :img="item"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mainMenuList: {
      type: Array,
      default: () => [],
    },
    columnCount: {
      type: Number,
      default: 6,
    }
  },
  data() {
    return {
      // columnCount: 6, // 根据屏幕宽度动态调整列数
    };
  },
  methods: {
    getItemsForColumn(columnIndex) {
      return this.mainMenuList.filter((_, index) => index % this.columnCount === columnIndex - 1);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
}

.column {
  flex: 1;
  margin: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
  white-space: nowrap; /* 禁止文本换行 */
}

.item {
  margin-bottom: 15px;
  text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
  white-space: nowrap; /* 禁止文本换行 */
}
</style>
