<!--
  功能：平台是否适配.适配打勾 不适配打叉
  时间：2025年03月10日 14:52:04
  版本：v1.0
-->
<template>
  <div class="flex items-center">
    <div
      v-for="platform in allPlatforms"
      :key="platform.name"
      class="w-4 h-4 relative mr-1"
    >
      <img
        :src="platform.image"
        :alt="platform.name"
      />
      <div class="absolute -right-1 topHalf">
        <p
          class="text-green-600 text-xs text-center"
          v-if="isPlatformAvailable(platformString, platform.name)"
        >
          ✔
        </p>
        <p
          class="text-red-600 text-xs text-center"
          v-else
        >
          X
        </p>
      </div>
    </div>
  </div>
</template>

<script>
const facebook = require('./icon/facebook.png')
const tiktok = require('./icon/tiktok.png')
export default {
  // 组件名称
  name: 'demo',
  // 组件参数 接收来自父组件的数据
  props: {
    platformString: {
      type: String,
      default: '',
    }
  },
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      allPlatforms: [
        { name: 'facebook', image: facebook },
        { name: 'tiktok', image: tiktok },
      ],
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    isPlatformAvailable(availablePlatforms,name) {
      const arr = availablePlatforms ? availablePlatforms.split(',') : []
      return arr.includes(name);
    },
  },
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {},
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
  /**
   * 实例销毁之前调用。在这一步，实例仍然完全可用。
   */
  beforeDestroy() {},
  /**
   * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
   * 所有的事件监听器会被移除，所有的子实例也会被销毁。
   */
  destroyed() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style scoped lang="scss">
.w-4{
  width: 1rem;
}
.topHalf{
  top: 50%;
}
</style>
